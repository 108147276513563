
import izmirKizLisesi1 from "../resources/images/posts/izmir-kiz-lisesi-1.png";
import fevziCakmakOrtaOkul1 from "../resources/images/posts/fevzi-cakmak-orta-okul-1.png";
import beachCleaning1 from "../resources/images/posts/beach-cleaning-1.png";
import beachCleaning2 from "../resources/images/posts/beach-cleaning-2.png";
import beachCleaning3 from "../resources/images/posts/beach-cleaning-3.png";
import beachCleaning4 from "../resources/images/posts/beach-cleaning-4.png";
import beachCleaningAward from "../resources/images/posts/beach-cleaning-award.png";
import beadedNecklace1 from "../resources/images/posts/beaded-necklace-1.png";
import beadedNecklace2 from "../resources/images/posts/beaded-necklace-2.png";
import beadedNecklace3 from "../resources/images/posts/beaded-necklace-3.png";
import beadedNecklace4 from "../resources/images/posts/beaded-necklace-4.png";
import beadedNecklace5 from "../resources/images/posts/beaded-necklace-5.png";
import cover from "../resources/images/cover.jpeg";
import novelCharacter1 from "../resources/videos/novel-character-1.mp4";
import novelCharacter2 from "../resources/videos/novel-character-2.mp4";
import novelCharacter3 from "../resources/videos/novel-character-3.mp4";
import violin29Ekim from "../resources/videos/violin-29-ekim.mp4";
import violin from "../resources/images/posts/violin.jpeg";
import awareness1 from "../resources/images/posts/awareness-1.jpeg";
import awareness2 from "../resources/images/posts/awareness-2.jpeg";

import cleaningCertificate from "../resources/images/certificates/cleaning.jpeg";
import englishLanguageCertificate from "../resources/images/certificates/english-language.jpeg";
import farkindalikCalismasiCertificate from "../resources/images/certificates/farkindalik-calismasi.jpeg";
import footbalCertificate from "../resources/images/certificates/football.jpeg";
import intelligenceGamesCertificate1 from "../resources/images/certificates/intelligence-games-contest-1.jpeg";
import intelligenceGamesCertificate2 from "../resources/images/certificates/intelligence-games-contest-2.jpeg";
import mathCertificate from "../resources/images/certificates/math.jpeg";
import violinCertificate from "../resources/images/certificates/music-violin.jpeg";
import tubitakCertificate from "../resources/images/certificates/tubitak.jpeg";
import ustunBasariCertificate from "../resources/images/certificates/ustun-basari.jpeg";

import profileImage from "../resources/images/profile-image-cropped.jpeg";
import InstagramIcon from "../resources/icons/InstagramIcon";
import FacebookIcon from "../resources/icons/FacebookIcon";
import TwitterIcon from "../resources/icons/TwitterIcon";

export const resources = {
    izmirKizLisesi1: izmirKizLisesi1,
    fevziCakmakOrtaOkul1 : fevziCakmakOrtaOkul1,
beachCleaning1 : beachCleaning1,
beachCleaning2 : beachCleaning2,
beachCleaning3 : beachCleaning3,
beachCleaning4 : beachCleaning4,
beachCleaningAward : beachCleaningAward,
beadedNecklace1 : beadedNecklace1,
beadedNecklace2 : beadedNecklace2,
beadedNecklace3 : beadedNecklace3,
beadedNecklace4 : beadedNecklace4,
beadedNecklace5 : beadedNecklace5,
cover : cover,
novelCharacter1 : novelCharacter1,
novelCharacter2 : novelCharacter2,
novelCharacter3 : novelCharacter3,
violin29Ekim : violin29Ekim,
violin : violin,
awareness1 : awareness1,
awareness2 : awareness2,
cleaningCertificate : cleaningCertificate,
englishLanguageCertificate : englishLanguageCertificate,
farkindalikCalismasiCertificate : farkindalikCalismasiCertificate,
footbalCertificate : footbalCertificate,
intelligenceGamesCertificate1 : intelligenceGamesCertificate1,
intelligenceGamesCertificate2 : intelligenceGamesCertificate2,
mathCertificate : mathCertificate,
violinCertificate : violinCertificate,
tubitakCertificate : tubitakCertificate,
ustunBasariCertificate : ustunBasariCertificate,
profileImage : profileImage,
InstagramIcon : InstagramIcon,
FacebookIcon : FacebookIcon,
TwitterIcon : TwitterIcon,
}