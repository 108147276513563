import {Fragment, useState} from "react";
import {nanoid} from "nanoid";

const MediaCarousel = ({mediaContent, showFullSize}) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const handleSlideChange = (newSlide) => {
        setCurrentSlide(prevState => {
            if (newSlide < 0)
                return mediaContent.length - 1;
            else if (newSlide >= mediaContent.length)
                return 0;
            else if (newSlide !== prevState)
                return newSlide
        })
    }
    return <div id="default-carousel" className="relative" data-carousel="static">
        <div className={"relative min-h-[48] overflow-hidden"}>
            {/*-- mediaContent -->*/}
            {mediaContent.map((mediaItem, index) =>
                <div key={nanoid(4)}
                     className={index === currentSlide ? "duration-700 ease-in-out" : "hidden duration-700 ease-in-out"}>
                    {mediaItem.type === "video" &&
                        <div className={mediaItem.aspectRatio}>
                            <iframe title={nanoid(6)} width="280" height="157" src={mediaItem.src} frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>

                    }
                    {mediaItem.type === "localVideo" &&
                        <video id={nanoid(8)} width="100%" className={showFullSize !== undefined && "max-h-screen"} controls>
                            <source src={mediaItem.src} type="video/mp4"/>
                            Sorry, your browser doesn't support videos.
                        </video>
                    }
                    {mediaItem.hasOwnProperty("type") === false &&
                        <div className={showFullSize !== undefined ? "max-w-1/3" : "h-52"}>
                            < img src={mediaItem.src}
                                  className="block max-w-full 100%; w-auto h-auto"
                                  alt={mediaItem.alt}
                            />
                        </div>
                    }
                </div>
            )}
        </div>
        {mediaContent.length > 1 &&
            <Fragment>
                {/*-- Slider indicators -->*/}
                <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
                    {mediaContent.map((imageItem, index) =>
                        <button key={nanoid(4)} type="button"
                                className={index === currentSlide ? "h-3 w-3 rounded-full bg-gray-900/50 ring-2 ring-gray-700/70 " : "h-3 w-3 rounded-full bg-blue-200/80 ring-2 ring-gray-700/80 "}
                                aria-current="false" aria-label={"Slide " + index}
                                onClick={e => { // used this to prevent detecting mouse click of outer div when, it is clicked on this
                                    handleSlideChange(index);
                                    e.stopPropagation();
                                }}/>
                    )}
                </div>
                {/*-- Slider controls -->*/}

                <button type="button"
                        className="group absolute top-0 left-0 z-30 flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none"
                        onClick={(e) => {
                            handleSlideChange(currentSlide - 1);
                            e.stopPropagation();
                        }}>
            <span
                className="inline-flex h-8 w-8 items-center justify-center rounded-full
                        bg-blue-200/50 ring-2 ring-blue-200/10
                        group-hover:bg-gray-500/50 group-hover:ring-2 group-hover:ring-white/50
                        sm:h-10 sm:w-10">
            <svg className="w-5 h-5 text-gray-900/70 group-hover:text-white/70 sm:w-6 sm:h-6" fill="none"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
            <span className="sr-only">Previous</span>
            </span>
                </button>
                <button type="button"
                        className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                        onClick={(e) => {
                            handleSlideChange(currentSlide + 1);
                            e.stopPropagation();
                        }}>
                    <span
                        className="inline-flex h-8 w-8 items-center justify-center rounded-full
                        bg-blue-200/50 ring-2 ring-blue-200/10
                        group-hover:bg-gray-500/50 group-hover:ring-2 group-hover:ring-white/50
                        sm:h-10 sm:w-10">
            <svg className="w-5 h-5 text-gray-900/70 group-hover:text-white/70 sm:w-6 sm:h-6" fill="none"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
            <span className="sr-only">Next</span>
            </span>
                </button>
            </Fragment>
        }
    </div>

}
export default MediaCarousel