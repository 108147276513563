
const AboutSectionContent = ({aboutMe, sectionClass}) => {
    return (
        <div className="py-20 px-4 mx-auto max-w-screen-xl text-center w-5/6 md:w-1/2
                space-y-8
                ">
            <h1 className={sectionClass.header}>
                About Me</h1>

            <div
                className="inline-flex overflow-hidden relative justify-center items-center
                        w-36 h-36
                        bg-gray-100 rounded-full dark:bg-gray-600">
                <img className="object-cover w-full h-full" src={aboutMe.profileImage} alt="Rounded avatar"/>
            </div>

            <p className={sectionClass.p}>
                {aboutMe.info}
            </p>
        </div>
    )
}

export default AboutSectionContent