import {nanoid} from "nanoid";
import MediaCarousel from "./common/MediaCarousel";

const schoolClass = {
    header: "pt-5 text-lg font-semibold text-gray-900",
    time: "block mb-2 text-sm font-normal leading-none text-gray-400",
    p: "text-base font-normal text-gray-500"
}
const SchoolsSectionContent = ({schools}) => {
    return (
        <div className="py-20 px-4 mx-auto max-w-screen-xl text-center w-5/6 sm:w-3/4
                space-y-8
                ">
            <h1 className="
                    text-black text-3xl font-light uppercase tracking-tight leading-none">
                My Schools</h1>

            <ol className="items-start flex justify-center flex-wrap">
                {schools.map((school) => (
                    <li className="relative mb-6 max-w-sm p-8"
                        key={nanoid(6)}>
                        <div className="mt-3">
                            {school.mediaContent &&
                                <div className={"pt-5"}><div className={"rounded-md w-80 overflow-hidden border"}><MediaCarousel mediaContent={school.mediaContent}/></div></div>
                            }
                            <h3 className={schoolClass.header}>
                                {school.name}
                            </h3>
                            <time className={schoolClass.time}>
                                {school.date}
                            </time>
                            <p className={schoolClass.p}>
                                {school.info}
                            </p>
                        </div>
                    </li>
                ))}

            </ol>
        </div>
    )
}

export default SchoolsSectionContent