import {nanoid} from 'nanoid';

import SchoolsSectionContent from "./Sections/SchoolsSectionContent";
import HobbiesSectionContent from "./Sections/HobbiesSectionContent";
import ActivitySectionContent from "./Sections/ActivitySectionContent";
import AchievementsSectionContent from "./Sections/AchievementsSectionContent";
import ContactSectionContent from "./Sections/ContactSectionContent";
import AboutSectionContent from "./Sections/AboutSectionContent";

import {content} from '../data/content.data';
import {resources} from '../data/resources.data';

const sectionClass = {
    header: "text-black text-3xl font-light uppercase tracking-normal leading-none",
    p: "text-base font-normal text-[#3a3a3a] tracking-wide indent-8 leading-relaxed"
}

const Body = () => {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleSendMail = () => {
        window.location.href = `mailto:${content.mailTemplate.mail}?subject=${content.mailTemplate.subject || ""}&body=${content.mailTemplate.body || ""}`;
    }
    return (
        <div>
            <section className="bg-white w-full">
                <div className="relative overflow-hidden text-center">
                    <img src={resources.cover}/>
                    <h1 className="absolute font-light text-5xl sm:text-6xl text-white w-full   top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        {content.aboutMe.name.toUpperCase()}</h1>
                </div>
            </section>

            <section id={"about"} className="bg-white h-[80vh]">
                <AboutSectionContent aboutMe={content.aboutMe} sectionClass={sectionClass}/>
            </section>

            <section id={"schools"} className="bg-gray-100 ">
                <SchoolsSectionContent schools={content.schools}/>
            </section>

            <section id={"social-activities"} className="bg-white min-h-screen">
                <ActivitySectionContent activities={content.activities} sectionClass={sectionClass}
                                        openInNewTab={openInNewTab}/>
            </section>

            <section id={"hobbies"} className="bg-gray-100 min-h-screen">
                <HobbiesSectionContent hobbies={content.hobbies} sectionClass={sectionClass}
                                       openInNewTab={openInNewTab}/>
            </section>

            <section id={"achievements"} className="bg-white min-h-screen">
                <AchievementsSectionContent achievements={content.achievements} sectionClass={sectionClass}
                                            openInNewTab={openInNewTab}/>
            </section>

            <section id={"contact"} className="bg-gray-100 min-h-screen/2">
                <ContactSectionContent sectionClass={sectionClass} handleSendMail={handleSendMail}/>
            </section>
        </div>
    );
}

export default Body;