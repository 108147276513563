import {nanoid} from "nanoid";
import {content} from '../data/content.data'
const Footer = () => {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <div className="
            bg-[#222222] px-4">
            <div className="
                flex justify-items-center justify-center space-x-1 pt-12 pb-8">
                {content.socials.map((social) => (
                    <div className={social.className}
                         key={nanoid(6)}
                         onClick={() => openInNewTab(social.address)}>
                        <div className={"w-12 h-12 flex justify-items-center justify-center hover:cursor-pointer"}>
                            {social.icon}
                        </div>
                    </div>
                ))}
            </div>
            <div className="
                flex justify-items-center justify-center pb-6">
                <a className={"text-sm text-[#AAAAAA]"}
                > Website Design is Inspired from <span className={"text-[#4480A6] hover:text-[#5CB9F2] hover:cursor-pointer"}
                                           onClick={() => openInNewTab("https://brittanychiang.com")}>Brittany Chiang</span>'s website</a>
            </div>
        </div>
    );
}

export default Footer;