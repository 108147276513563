import {nanoid} from 'nanoid';
import {resources} from './resources.data';


export const content = {
    socials: [
        {name: "instagram", address: "https://www.instagram.com/mehlikabilici", icon: <resources.InstagramIcon width={20} fill={"#D6D6D6"}/>, className: "bg-[#3a3a3a] hover:bg-[#4480A6] hover:scale-110 transform transition duration-500"},
        {name: "twitter", address: "https://www.twitter.com/mehlika_bilici", icon: <resources.TwitterIcon width={21} fill={"#D6D6D6"}/>, className: "bg-[#3a3a3a] hover:bg-[#5CB9F2] hover:scale-110 transform transition duration-500"},
    ],
    mailTemplate: {
        mail: "mehlikabilici@gmail.com",
        subject: "Hello",
        body: "Hi,",
    },
    aboutMe: {
        name: "Mehlika Bilici",
        profileImage: resources.profileImage,
        info: "Hello there! My name is Mehlika. I’m a senior student in Izmir Kız High School. I’m planning to go to a university in abroad. I want to travel around the world, see new places and learn new cultures. In my free times I like reading books, skating, hiking, playing violin, making beaded necklaces and watching movies or shows. I’m interested in technology and I love doing math so I want to study Computer Science."
    },
    schools: [
        {
            name: "Fevzi Çakmak Middle School",
            info: "From 6 to 8th grade I went to Fevzi Çakmak Middle School. I had a lot of different teachers. All of them were very good and supportive. Whenever I needed help they were always there. I wouldn’t be in this school if they weren’t this helpful.",
            mediaContent: [
                {id: nanoid(4), alt: "2", src: resources.fevziCakmakOrtaOkul1},
            ]
        },
        {
            name: "Izmir Kız High School",
            info: "After Middle School I get into Izmir Kız High School. In Turkey there is an high school entrance exam called LGS. You go to a school according to your score. I was in the 1.45 percentage so I entered Izmır Kız High School, one of the best high schools in Izmır, with the first place. It is a beautiful school with its architecture and people. Also it is so close to sea that we can even see it from our classroom’s windows. The students and teachers in there are very qualified. It is the best years of my life thanks to my caring teachers and lovely friends",
            mediaContent: [
                {id: nanoid(4), alt: "1", src: resources.izmirKizLisesi1},
            ]
        },
    ],
    activities : [
            {
                id: nanoid(6),
                name: "Cleaning the beaches",
                info: "In summers, we usually go to our hometown Bozyazı. This year when we went swimming I realized that there were a lot of garbage in the beache. So the next time we went swimming we brought some plastic bags and collected some of the garbage. Each time we went swimming we cleaned the beach. There were a lot of different things like torn clothes, used diapers, plastic bottles and bags, and millions of cigarettes. Sometimes when I tried to take some bottles in the sand they fall in the pieces as I touches them. They must have been in there for years. Some of my cousins came to help me sometimes. People who saw us collecting celebrated us. I never thought there would be this much garbage. I wish people could have learn how to be human.",
                mediaContent: [
                    {id: nanoid(4), alt: "1", src: resources.beachCleaning1},
                    {id: nanoid(4), alt: "2", src: resources.beachCleaning2},
                    {id: nanoid(4), alt: "3", src: resources.beachCleaning3},
                    {id: nanoid(4), alt: "4", src: resources.beachCleaning4},
                ]
            },
            {
                id: nanoid(6),
                name: "April 23 (National Sovereignty and Children's Day)",
                info: "In March and April I went to a kindergarten almost every week and played some songs on my violin to little kids. It was fun because they were also singing along. On April 23 we made a show. Children’s parents came and watched. I played and the children sang the songs.",
                mediaContent: [
                    {
                        id: nanoid(4),
                        alt: "Video: Playing Violin at a kindergarten",
                        type: "video",
                        aspectRatio: "aspect-w-16 aspect-h-9",
                        src: "https://www.youtube.com/embed/_4cjbPVzHL0"
                    },
                ]
            },
            {
                id: nanoid(6),
                name: "October 29 (Republic Day)",
                info: "On October 29,1923 the status of the nation as a republic was declared in Turkey. This year it was its 99th anniversary. We celebrated it with children from kindergarten. I played violin and they sang",
                link: "https://www.instagram.com/reel/CkQJQuaooIv/?utm_source=ig_embed",
                mediaContent: [
                    {
                        id: nanoid(4),
                        alt: "2",
                        type: "video",
                        aspectRatio: "aspect-w-16 aspect-h-9",
                        src: resources.violin29Ekim
                    },
                ]
            },
            {
                id: nanoid(6),
                name: "Novel Characters Day",
                info: "Last year our teachers organized a contest. We were going to dressed up as a novel character we want to be. But later they said that we can be a character from a movie or series too. So I decided to be Obi-Wan Kenobi from Star Wars because I already had a lightsaber at home. My mom made a robe and the clothes for me. Everything was perfect. There were a lot of students with beautiful costumes. Everyone loved mine. Lots of people wanted to take photos with me or a video of me spinning my lightsaber. I had a lot of fun. Now everyone remembers me from that day. Sometimes some students say “Oh last year you were the one who spinned the lightsaber right?” or “ Oh she is the star wars girl” I became the forth in the contest. Most people said I deserved to be the winner. But it was worth everything. If they do something like this again I’m planning to be Anakin Skywalker next.",
                // address: "https://en.wikipedia.org/wiki/Wikipedia"
                mediaContent: [
                    {
                        id: nanoid(4),
                        alt: "1",
                        type: "localVideo",
                        aspectRatio: "aspect-w-9 aspect-h-16",
                        aspectRatioVertical: true,
                        src: resources.novelCharacter1,
                    },
                    {
                        id: nanoid(4),
                        alt: "2",
                        type: "localVideo",
                        aspectRatio: "aspect-w-9 aspect-h-16",
                        aspectRatioVertical: true,
                        src: resources.novelCharacter2,
                    },
                    {
                        id: nanoid(4),
                        alt: "3",
                        type: "localVideo",
                        aspectRatio: "aspect-w-9 aspect-h-16",
                        aspectRatioVertical: true,
                        src: resources.novelCharacter3,
                    },
                ]
            },
            {
                id: nanoid(6),
                name: "Izmir Awareness Center",
                info: "Last year, in 11th grade our school organized a trip to a place called awareness center. It was a place to help us understand how disabled people feel and what kinda hardship they face everyday. We try to use wheelchairs. I never realized how hard it is to go up on a ramp. They taught us some sign language and talked about autism. It really helped me to look from their perspective. They put us in a dark room where we can see absolute nothing and asked us to find some stuff and do chores like finding the vacuum cleaner and vacuum the floor. It learned a lot if things. I wish everyone could go and learn.",
                address: "https://www.facebook.com/ibbfarkindalikmerkezi/posts/pfbid02h9WnRDGBBJV6UnnRRuHSJggXdbsT3XWfcGMr1fc4XMs4yamkEPH7U9DiEdA7DFVhl",
                mediaContent: [
                    {
                        id: nanoid(4),
                        alt: "1",
                        src: resources.awareness1,
                    },
                    {
                        id: nanoid(4),
                        alt: "2",
                        src: resources.awareness2,
                    },
                ]
            },
        ],
    hobbies: [
            {
                id: nanoid(6),
                name: "Violin",
                info: "In 5th grade I was in a private school so everyone was supposed to have a musical instrument so I chose violin. But then I changed my school and stopped playing violin. Later in 7th grade I started to take a course from somewhere close to our house. In 9th grade we did online lessons due to pandemic. But in 11th grade I took a break to prepare my exams. I have a YouTube channel where I share some of the songs I can play with my violin. Here’s the link.",
                address: "https://youtube.com/channel/UCeyH2j_TTTxr7Ibt_3JaSbQ",
                mediaContent: [
                    {
                        id: nanoid(4),
                        alt: "2",
                        src: resources.violin
                    },
                ]
            },
            {
                id: nanoid(6),
                name: "Beaded Necklace",
                info: "During the pandemic one of my friends gave me a beaded necklace she made and I like it so much. So I decided to do necklaces too. She taught me how to do them. Since then I did lots of different necklaces. I sold some of them to my school friends, teachers and to other students in school. I love wearing things I did myself and being able to wear the thing I wanted.",
                mediaContent: [
                    {id: nanoid(4), alt: "1", src: resources.beadedNecklace1},
                    {id: nanoid(4), alt: "2", src: resources.beadedNecklace2},
                    {id: nanoid(4), alt: "3", src: resources.beadedNecklace3},
                    {id: nanoid(4), alt: "4", src: resources.beadedNecklace4},
                    {id: nanoid(4), alt: "5", src: resources.beadedNecklace5},
                ]
            },
            {
                id: nanoid(6),
                name: "Swimming",
                info: "In our hometown Bozyazı our house is really close to sea. So we go to sea almost every day. I love swimming and I am good at it. I love it more when we go swimming with my cousins because we play lots of games like who will swim faster or who can hold their breath longer.",
                mediaContent: [
                    {id: nanoid(4), alt: "1", src: "https://cdn.pixabay.com/photo/2016/11/14/22/18/beach-1824855__480.jpg"},
                ]
            },
            {
                id: nanoid(6),
                name: "Nature walks/Picnics",
                info: "In weekends we go hiking or picnic with my family. I love these days because they are like a break from all the noise of the city. It is relaxing. Sometimes we go with my friends. We sing songs, chat, try to climb to trees and go walking in forest. We dive into the trees and explore new places. I love those time because we are on our own. We leave some marks around and try to memorize trees to not get lost when turning back.",
                mediaContent: [
                    {id: nanoid(4), alt: "1", src: "https://cdn.pixabay.com/photo/2020/10/27/20/17/trees-5691803__480.jpg"},
                ]
            },
        ],
    achievements: [
        {
            id: nanoid(6),
            name: "Language",
            info: "Since 4th grade I was into English. I went to a private school and we had 20 hours of english each week in there. In 9th grade I started Turkish American Association’s English course. I took B2, C1, C2 and TOEFL courses in there. Now I have C2 certificate.",
            mediaContent: [
                {
                    id: nanoid(4),
                    alt: "1",
                    src: resources.englishLanguageCertificate
                },
            ]
        },
        {
            id: nanoid(6),
            name: "Math",
            info: "From 8 to 11th grade I took math lessons from a private teacher. It was once a week. I used to asked him the questions I couldn’t solve or the topics I didn’t understand. I love solving math. It makes me happier when I solve each question.",
            mediaContent: [
                {
                    id: nanoid(4),
                    alt: "1",
                    src: resources.mathCertificate
                },
            ]
        },
        {
            id: nanoid(6),
            name: "Violin",
            info: "This is the certificate that I earned because I finished my violin course. ",
            mediaContent: [
                {
                    id: nanoid(4),
                    alt: "1",
                    src: resources.violinCertificate
                },
            ]
        },
        {
            id: nanoid(6),
            name: "Tübitak",
            info: "Last year our literature teacher was trying to make a book from the stories and poems written by students. She asked me and 3 of my friends to translate some of them to English and we accepted. We translated every story and poem carefully our english teacher check the grammar so it was ready just on time. We attended to Tübitak (Scientific and Technological Research Council of Turkey) with this book. The day of the presentations I also sold some handmade purses and little bags to the students. First we were going to donate the money to disabled people but because it wasn’t too much we used the money to improve our school library. ",
            mediaContent: [
                {id: nanoid(4), alt: "1", src: resources.tubitakCertificate},
            ]
        },
        {
            id: nanoid(6),
            name: "Intelligence games contest",
            info: "Last year when we were in the break time with my friends we saw a poster on the wall. It was a contest about intelligence games. We decided to give it a shot. It was for all student grades. Every group was consist of four students. Totally there were 10 groups. They gave us lots of different games like sudokus, tangrams, BLA BLA. After one week they announced the winner. It was our group. We were so happy. We didn’t just won a certificate but a money prize too.",
            mediaContent: [
                {id: nanoid(4), alt: "1", src: resources.intelligenceGamesCertificate1},
                {id: nanoid(4), alt: "1", src: resources.intelligenceGamesCertificate2},
            ]
        },
        {
            id: nanoid(6),
            name: "Cleaning",
            info: "After hearing our cleaning the mayor of Bozyazi called and invited us to drink tea. He gave me a present and a thank you letter. I felt so happy.",
            mediaContent: [
                {id: nanoid(4), alt: "1", src: resources.cleaningCertificate},
                {id: nanoid(4), alt: "2", src: resources.beachCleaningAward},
            ]
        },
        {
            id: nanoid(6),
            name: "Certificate of Outstanding Achievement",
            info: "This certificate is given to students who had the Certificate of Apprecation(given to students who has an grade average above 85/100) for at least 3 years. I got this certificate last year because my grade was above 85 for 6 semester which equals to 3 years.",
            mediaContent: [
                {id: nanoid(4), alt: "1", src: resources.ustunBasariCertificate},
            ]
        },
        {
            id: nanoid(6),
            name: "School’s Girl Football Team",
            info: "n 9th grade I joined an election for the school’s girl football team. We did some training and then I got in but because of the pandemic I never had the chance to play.",
            mediaContent: [
                {id: nanoid(4), alt: "1", src: resources.footbalCertificate},
            ]
        },
        {
            id: nanoid(6),
            name: "Awareness Certificate",
            info: "This certificate was given by Tunç Soyer, the mayor of Izmır, to thank us for participating to awareness program.",
            mediaContent: [
                {id: nanoid(4), alt: "1", src: resources.farkindalikCalismasiCertificate},
            ]
        },
    ],
}