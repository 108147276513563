import {Fragment, useEffect, useState} from "react";

const Popup = ({enablePopup, closeOnClickOutside, onClose, ...props}) => {
    const [enabled, setEnabled] = useState(true);
    useEffect(() => {
        if(enablePopup !== undefined)
            setEnabled(enablePopup);
    }, [enablePopup])

    return (
        <Fragment>
            { enabled === true &&
                <div id="popup-modal" tabIndex="-1"
                     onClick={()=> {if(closeOnClickOutside !== undefined) { setEnabled(false); onClose();}}}
                     className="bg-gray-900/50 fixed flex justify-center top-0 left-0 w-full h-full z-50 overflow-y-auto">
                    <div
                        onClick={e => { // used this to prevent detecting mouse click of outer div when, it is clicked on this
                            e.stopPropagation();
                        }}
                        className="absolute z-100 pb-10 sm:w-[90vh]  lg:w-[100vh] bg-white shadow">
                            {props.children}
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Popup