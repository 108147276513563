import LinkIcon from "../../../resources/icons/LinkIcon";
import {useState} from "react";
import {nanoid} from "nanoid";
import MediaCarousel from "./MediaCarousel";
import Popup from "../../common/Popup";

const ItemSectionContent = ({header, items, sectionClass, openInNewTab, onItemClickShowPopup, showFullSize}) => {
    const [showReadMore, setShowReadMore] = useState({})
    const handleReadMore = (id, newValue) => {
        setShowReadMore(prevState => {
            const fieldValuePair = {};
            fieldValuePair[id] = newValue;
            return {...prevState, ...fieldValuePair}
        })
    }

    const [popUpEnabled, setPopUpEnabled] = useState(false);
    const [popUpItem, setPopUpItem] = useState({})
    const handlePopUpEnable = () => {
        if(onItemClickShowPopup === undefined)
            return;

        setPopUpEnabled(true)
    }
    const handlePopUpClose = () => {
        if(onItemClickShowPopup === undefined)
            return;

        setPopUpEnabled(false)
    }

    const isTextTooLong = (textLength) => parseInt(textLength) > 250
    const isShowReadMoreTrue = (id) => {
        if (showReadMore.hasOwnProperty(id))
            return showReadMore[id]
        else
            return true;
    }
    return (
        <div className="py-20 px-4 mx-auto max-w-screen-xl text-center  w-5/6 sm:w-3/4
                space-y-8
                flex justify-items-center grid grid-cols-1
                ">
            <h1 className={sectionClass.header}>
                {header}</h1>

            <div className="items-start flex justify-center flex-wrap gap-4">
                {items.map((item) => {
                    return <div className="max-w-sm w-80 rounded overflow-hidden border shadow-lg pt-4
                            hover:scale-105 transform transition duration-500 hover:z-3
                            text-center
                            "
                                key={nanoid(4)}
                                onClick={() => {
                                    setPopUpItem(item)
                                    handlePopUpEnable()
                                }}
                    >
                        {item.mediaContent && item.mediaContent.length > 0 &&
                            <MediaCarousel showFullSize={showFullSize} mediaContent={item.mediaContent}/>
                        }
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">{item.name}</div>
                            <p className="text-gray-700 text-base">
                                {isTextTooLong(item.info.length) && isShowReadMoreTrue(item.id) ? item.info.substring(0, 200) + "..." : item.info}
                            </p>
                            {isTextTooLong(item.info.length) &&
                                <button onClick={() =>{
                                    if(onItemClickShowPopup === undefined)
                                        handleReadMore(item.id, !isShowReadMoreTrue(item.id))
                                }}
                                        className={isShowReadMoreTrue(item.id) ? "text-blue-400 font-semibold" : "text-purple-400 font-semibold"}
                                >
                                    {isShowReadMoreTrue(item.id) ? "read more" : "read less"}
                                </button>}
                        </div>
                        {item.address ?
                            <div className="grid justify-items-center"
                                 onClick={() => openInNewTab(item.address)}>
                                <div
                                    className={" w-12 h-12 flex justify-center place-self-center hover:scale-125 transform transition duration-500"}>
                                    {<LinkIcon width={20} fill={"#999999"}/>}
                                </div>

                            </div>
                            :
                            <div className={" w-12 h-12"}>
                            </div>
                        }
                    </div>
                })}

            </div>

            {onItemClickShowPopup !== undefined && popUpEnabled &&
                <Popup enablePopup={popUpEnabled} closeOnClickOutside onClose={handlePopUpClose}>
                    <div className="text-center p-3 overflow-y-scroll"
                         key={nanoid(4)}
                    >
                        <button type="button"
                                onClick={handlePopUpClose}
                                className="z-50 absolute top-0 right-0 text-white bg-black/90 hover:bg-gray-200 hover:text-gray-900 text-xl p-1.5 ml-auto inline-flex items-center"
                                data-modal-toggle="popup-modal">
                            <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clip-rule="evenodd"></path>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className={""}>
                            {popUpItem.mediaContent && popUpItem.mediaContent.length > 0 &&
                                <MediaCarousel showFullSize mediaContent={popUpItem.mediaContent}/>
                            }
                        </div>
                        <div className="font-bold text-xl mb-2 px-6 pt-6">{popUpItem.name}</div>
                        <p className="text-gray-700 text-base px-6 pt-2">
                            {popUpItem.info}
                        </p>
                        {popUpItem.address &&
                            <div className="grid justify-items-center"
                                 onClick={() => openInNewTab(popUpItem.address)}>
                                <div
                                    className={" w-12 h-12 flex justify-center place-self-center hover:cursor-pointer hover:scale-125 transform transition duration-500"}>
                                    {<LinkIcon width={20} fill={"#999999"}/>}
                                </div>

                            </div>
                        }
                    </div>
                </Popup>
            }
        </div>
    )
}

export default ItemSectionContent