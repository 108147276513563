import {GrSend} from "react-icons/gr";

const ContactSectionContent = ({sectionClass, handleSendMail}) => {
    return (
        <div className="py-20 px-4 mx-auto max-w-screen-xl text-center w-5/6 sm:w-3/4
                space-y-8
                ">
            <div style={{display: "flex", justifyContent: "center"}}>
                <GrSend className={"w-12 h-20"}/>
            </div>
            <h1 className={sectionClass.header}>
                Contact</h1>
            <p className={sectionClass.p}>
                Whether you have an idea for a project or just want to chat,
                <br/>
                feel free to shoot me an email! <a className={"text-blue-500 hover:cursor-pointer"} onClick={handleSendMail}>mehlikabilici@gmail.com</a>
            </p>
            <button
                className={"border-solid border-2 border-black hover:border-[#0903A6] hover:bg-[#0903A6] hover:text-white hover:scale-105 transform transition duration-500"}
                onClick={handleSendMail}
            >
                <div className={"px-4 py-2"}
                >
                    Say Hello
                </div>
            </button>
        </div>
    )
}

export default ContactSectionContent