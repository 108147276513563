export const config = {
    websiteAddress: "https://www.mehlikabilici.net",
    websiteName: "Mehlika Bilici",
    menuItems: [
        {label: "About", href: "#about"},
        {label: "Schools", href: "#schools"},
        {label: "Social Activities", href: "#social-activities"},
        {label: "Hobbies", href: "#hobbies"},
        {label: "Achievements", href: "#achievements"},
        {label: "Contact", href: "#contact"},
    ]
}