import {useState} from "react";
import {useMediaQuery} from 'react-responsive';
import {HashLink} from "react-router-hash-link";
import {nanoid} from "nanoid";
import {config} from "../data/config.data"


const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    const handleMobileMenuItemClick = ()=> {
        setShowMobileMenu(false)
    }
   
    return (
        <header className={"sticky top-0 z-50"}>
            <nav className="bg-black border-gray-200 px-4 lg:px-6">
                <div className={showMobileMenu ? "justify-between items-center flex flex-wrap max-w-screen-xl"
                    : "justify-between items-center flex flex-wrap mx-auto max-w-screen-xl"}>
                    <a href={config.websiteAddress}
                       className="flex items-center py-2 text-white hover:text-blue-200 hover:scale-110 transform transition duration-500">
                        <span
                            className="uppercase self-center font-semibold whitespace-nowrap text-base">{config.websiteName}</span>
                    </a>

                    {isMobile &&
                        <div className="flex items-center lg:order-2">
                            <button type="button"
                                    className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                    aria-controls="mobile-menu-2" aria-expanded="false"
                                    onClick={handleMobileMenuItemClick}
                            >
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                          clipRule="evenodd"></path>
                                </svg>
                                <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </button>
                        </div>
                    }
                    {(isMobile === false) &&
                        <div className=" flex flex-wrap
                        justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
                        >
                            <ul className="flex text-sm font-medium flex-row ">
                                {config.menuItems.map((menuItem => (
                                    <HashLink smooth to={menuItem.href}
                                              key={nanoid(6)}
                                              // onClick={()=>handleMenuItemClick(menuItem.href)}
                                    >
                                        <li className={"px-4 py-4 hover:bg-[#3a3a3a] hover:scale-110 transform transition duration-500"}>
                                            <p
                                               className={"uppercase hover: block text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"}
                                               aria-current="page">{menuItem.label}</p>
                                        </li>
                                    </HashLink>
                                )))}

                            </ul>
                        </div>
                    }
                </div>

                {showMobileMenu && <div>
                    <ul className="xl:hidden text-sm font-medium flex justify-items-center grid grid-cols-1">
                        {config.menuItems.map((menuItem => (
                            <li key={nanoid(6)}
                                className={"px-4 py-2 hover:bg-[#3a3a3a] hover:scale-110 transform transition duration-500"}>
                                <a href={menuItem.href}
                                   onClick={handleMobileMenuItemClick}
                                   className={"uppercase hover: block py-1 px-1 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"}
                                   aria-current="page">{menuItem.label}</a>
                            </li>
                        )))}
                    </ul>
                </div>
                }
            </nav>
        </header>
    )}

export default Header
